import Markdoc from '@markdoc/markdoc';
import React, { useMemo } from 'react';

import { BackgroundImage, BackgroundImageConfig } from './BackgroundImage';
import { CallToAction, CallToActionConfig } from './CallToAction';
import { Check, CheckConfig } from './Check';
import { ColumnDisplay, columnDisplayConfig } from './ColumnDisplay';
import { CourseOverview, CourseOverviewConfig } from './CourseOverview';
import { Div, DivConfig } from './Div';
import { FeatureTag } from './FeatureTag';
import { Foot, FootConfig } from './Foot';
import { Hero, HeroConfig } from './Hero';
// import { HeroTag } from './HeroTag';
import { ImageAndText, imageAndTextConfig } from './ImageAndText';
import { ImageTag, ImageTagConfig } from './Img';
import style from './markdown.module.scss';
import { Pill, PillConfig } from './Pill';
import { Pillow, PillowConfig } from './Pillow';
import { convertNodesToOptions } from './RadioSelect';
import { Range, rangeConfig } from './Range';
import { Register, RegisterConfig } from './Register';
import type { SelectableListProps } from './SelectableList';
import { SelectableList, selectableListConfig } from './SelectableList';
import { SleepDiary, SleepDiaryConfig } from './SleepDiary';
import { TextAndText, textAndTextConfig } from './TextAndText';
import { TextArea, textAreaConfig } from './TextArea';
import { VideoPlayer, videoPlayerConfig } from './VideoPlayer';

const components = {
  VideoPlayer,
  // Hero: HeroTag,
  Feature: FeatureTag,
  SelectableList: (props: SelectableListProps) => {
    // Extract options from children
    const options = convertNodesToOptions(
      props.name,
      (props as any).children?.props?.children
    );
    return (
      <SelectableList
        name={props.name}
        options={options}
        other={props.other}
        multiple={props.multiple}
      />
    );
  },
  Range,
  TextArea,
  TextAndText,
  ImageAndText,
  ColumnDisplay,
  BackgroundImage,
  Register,
  Div,
  Check,
  CourseOverview,
  Hero,
  Pill,
  Pillow,
  CallToAction,
  Foot,
  ImageTag,
  SleepDiary,
};

const useMarkdown = (className: string = style.md) => {
  // create function that uses Markdoc.renderers.react to render markdown
  const markdown = useMemo(() => {
    return (md: string) => {
      if (!md) return null;
      const ast = Markdoc.parse(md);
      ast.attributes = {
        class: className,
      };
      const content = Markdoc.transform(ast, {
        tags: {
          ...videoPlayerConfig.tags,
          // ...HeroTag.config.tags,
          ...FeatureTag.config.tags,
          ...selectableListConfig.tags,
          ...rangeConfig.tags,
          ...textAreaConfig.tags,
          ...textAndTextConfig.tags,
          ...imageAndTextConfig.tags,
          ...columnDisplayConfig.tags,
          ...BackgroundImageConfig.tags,
          ...RegisterConfig.tags,
          ...DivConfig.tags,
          ...CheckConfig.tags,
          ...CourseOverviewConfig.tags,
          ...HeroConfig.tags,
          ...PillConfig.tags,
          ...PillowConfig.tags,
          ...CallToActionConfig.tags,
          ...FootConfig.tags,
          ...ImageTagConfig.tags,
          ...SleepDiaryConfig.tags,
        },
      });
      return Markdoc.renderers.react(content, React, { components });
    };
  }, []);
  return markdown;
};

export default useMarkdown;
