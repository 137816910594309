import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import Image from 'next/image';
import img from 'public/assets/images/pill.png';
import React, { useEffect, useState } from 'react';

export const PillConfig: ConfigType = {
  tags: {
    pill: {
      render: 'Pill',
      children: [],
    },
  },
};
interface PillProps {
  children?: React.ReactNode[];
}

export const Pill: React.FC<PillProps> = ({ children }) => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (width) {
      setIsMobile(width < 768);
    }
  }, [width]);
  return (
    <div className="mx-0 mb-5 flex flex-col items-start md:mx-5 md:flex-row md:flex-nowrap">
      <div
        className={classNames(
          'mt-3 mb-5 md:mr-1',
          isMobile ? 'flex justify-center w-full' : ''
        )}
        style={{ color: '#1C33AE' }}
      >
        <Image
          src={img}
          alt="pill"
          width={50}
          height={50}
          className="mr-2"
          style={{ width: '50px', height: '50px' }}
        />
      </div>
      <div className="float-left text-center text-xl text-black md:w-3/4 md:text-left md:text-2xl">
        {children}
      </div>
    </div>
  );
};
