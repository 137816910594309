import type { ConfigType } from '@markdoc/markdoc';
import React, { useState } from 'react';

import type { RadioOption } from './RadioSelect';

const config: ConfigType = {
  tags: {
    select: {
      render: 'SelectableList',
      children: ['option'],
      attributes: {
        name: {
          type: String,
          required: true,
        },
        other: {
          type: [Boolean, String],
          default: false,
        },
        multiple: {
          type: [Boolean, Number],
          default: false,
        },
      },
    },
  },
};

export interface SelectableListProps {
  name: string;
  options: RadioOption[];
  other: boolean;
  multiple?: boolean | number;
}

const SelectableList: React.FC<SelectableListProps> = ({
  name,
  options,
  other,
  multiple,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [otherText, setOtherText] = useState('');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Determine the new set of selected options based on user interaction
    let newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    // If there's a numerical limit, enforce it by keeping only the last X selections
    if (typeof multiple === 'number' && newSelectedOptions.length > multiple) {
      newSelectedOptions = newSelectedOptions.slice(
        newSelectedOptions.length - multiple
      );
    }

    setSelectedOptions(newSelectedOptions);
  };

  const handleOtherInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherText(event.target.value);
    // If typing in "Other" input, ensure "Other" is selected
    if (!selectedOptions.includes('other')) {
      setSelectedOptions([...selectedOptions, 'other']);
    }
  };

  const isMultiple = multiple === true || typeof multiple === 'number';

  return (
    <div className="mb-10">
      {options.map((option, index) => (
        <label key={index} className="block">
          <input
            type={isMultiple ? 'checkbox' : 'radio'}
            name={name}
            value={option.id}
            checked={selectedOptions.includes(option.id)}
            onChange={handleCheckboxChange}
            disabled={
              !isMultiple &&
              typeof multiple === 'number' &&
              selectedOptions.length >= multiple &&
              !selectedOptions.includes(option.id)
            }
          />
          {option.label}
        </label>
      ))}
      {other && (
        <>
          <label>
            <input
              type={isMultiple ? 'checkbox' : 'radio'}
              name={`${name}-other`}
              value="other"
              checked={selectedOptions.includes('other')}
              onChange={handleCheckboxChange}
            />
            Other:
          </label>
          {selectedOptions.includes('other') && (
            <input
              type="text"
              value={otherText}
              onChange={handleOtherInputChange}
            />
          )}
        </>
      )}
    </div>
  );
};

export { SelectableList, config as selectableListConfig };
