import { useContext } from 'react';

import { FormContext } from './FormContext';

// Custom hook to access and update the form state
export function useFormState(componentType: string, name: string) {
  const { state, dispatch } = useContext(FormContext);

  // Get the component state
  const componentState = state[name] || {
    type: componentType,
    name,
    value: '',
  };

  // Set the component state
  const setComponentState = (value: string | number | boolean | string[]) => {
    dispatch({
      type: 'SET_VALUE',
      payload: { name, value },
    });
  };

  return { state, componentState, setComponentState };
}
export function useFormStateResult() {
  const { state } = useContext(FormContext);

  return state;
}
