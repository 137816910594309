import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import { Button } from 'flowbite-react';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { IoArrowForwardOutline } from 'react-icons/io5';

export const FootConfig: ConfigType = {
  tags: {
    foot: {
      render: 'Foot',
      attributes: {
        className: {
          type: String,
          required: false,
        },
        title: {
          type: String,
          required: true,
        },
        desc: {
          type: String,
          required: true,
        },
      },
    },
  },
};

interface FootProps {
  className?: string;
  desc: string;
  title?: string;
}

export const Foot: React.FC<FootProps> = ({
  className,
  title = 'No pills. No electronics. No gimmicks.',
  desc,
}) => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (width) {
      setIsMobile(width < 768);
    }
  }, [width]);
  return (
    <div
      className={classNames(
        'bg-blue-50 px-10 md:px-32 pt-20 pb-20 text-center ',
        className
      )}
      style={{ borderTopLeftRadius: '2rem', borderTopRightRadius: '7rem' }}
    >
      <div className="flex flex-col items-center justify-center">
        <div className=" flex text-4xl font-semibold text-blue-800 md:text-5xl">
          {isMobile ? (
            <div style={{ lineHeight: '2.5rem' }}>
              <div>
                No pills.
                <br />
                No electronics.
                <br />
                No gimmicks.
              </div>
            </div>
          ) : (
            <div style={{ lineHeight: '3rem' }}>{title}</div>
          )}
        </div>
        <div className="py-10 text-xl font-semibold text-blue-800 md:text-3xl">
          {desc}
        </div>
        <div className=" pb-20">
          <Link href={`/course`}>
            <Button
              className="md:py-2"
              style={{
                width: '250px',
                borderRadius: '5rem',
                backgroundColor: 'rgb(30 64 175)',
              }}
            >
              <div className="flex items-center rounded-full">
                <div className="mr-1 text-xl font-bold">Start the course</div>
                <div>
                  <IoArrowForwardOutline />
                </div>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
