import { useProgram } from '@drugfreesleep/app/(program)/course/program.gql';
import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

const config: ConfigType = {
  tags: {
    courseOverview: {
      render: 'CourseOverview',
    },
  },
};

const CourseOverview: React.FC = () => {
  const { program, interactions } = useProgram(['contents']);
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (width) {
      setIsMobile(width < 768);
    }
  }, [width]);

  // console.log('program: ', program, "interactions: ", interactions)
  return (
    <div className="mx-5 py-3 md:mx-20">
      {program &&
        program.payload.contents.map((week: any, index: number) => {
          const interaction = interactions?.find((i) => i.id === week.id);
          return (
            <div
              key={index}
              className="my-1"
              style={{ maxHeight: '230px', lineHeight: '1.4rem' }}
            >
              {interaction && index !== 0 && (
                <div>
                  {isMobile && (
                    <Image
                      src={interaction.payload.img}
                      alt=""
                      width="72"
                      height="72"
                      className="mb-1"
                    />
                  )}
                  <div className="mb-2 flex items-center border-b  border-stone-300 pt-2 pb-3 md:mb-0">
                    {/* <div > */}
                    {!isMobile && (
                      <Image
                        src={interaction.payload.img}
                        alt=""
                        width="90"
                        height="90"
                        style={{ maxWidth: '90px', maxHeight: '90px' }}
                        className="mr-6"
                      />
                    )}
                    {/* </div> */}
                    <div className=" md:mt-4">
                      <div className="">
                        <div className="mb-2 text-lg font-semibold md:mb-4">
                          {interaction.name}
                        </div>
                        <div>
                          <div className="text-black-800 mb-4  text-sm leading-normal ">
                            {interaction.payload.desc}
                          </div>

                          <div
                            className="mb-2 text-gray-600"
                            style={{ fontSize: '12px' }}
                          >
                            {week.contents.length} Lessons
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      {isMobile && <div className="my-40"></div>}
    </div>
  );
};

export { CourseOverview, config as CourseOverviewConfig };
