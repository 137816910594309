import type { ConfigType } from '@markdoc/markdoc';
import { useState } from 'react';

const config: ConfigType = {
  tags: {
    textarea: {
      render: 'TextArea',
      attributes: {
        name: {
          type: String,
          required: true,
        },
        placeholder: {
          type: String,
          required: false,
        },
        maxLength: {
          type: Number,
          required: false,
        },
      },
    },
  },
};

interface TextAreaProps {
  name: string;
  maxLength?: number;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  maxLength,
  placeholder,
}) => {
  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <textarea
      name={name}
      value={value}
      maxLength={maxLength}
      onChange={handleChange}
      className="block w-full rounded-md border-gray-300 bg-inherit shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      placeholder={placeholder}
    />
  );
};

export { TextArea, config as textAreaConfig };
