import type { ConfigType } from '@markdoc/markdoc';
import { Alert, Button, Label } from 'flowbite-react';
import router from 'next/router';
import React, { useEffect, useReducer, useState } from 'react';
import { IoArrowForward } from 'react-icons/io5';

import { useAuth } from '../authentication/useAuth';
import { useRegistration } from '../authentication/useRegistration';
import { useAuthenticateUserMutation } from '../graphql/generated-types';
import { useFormState } from './useFormState';

type State = {
  username: string;
  password: string;
  confirmationMessage?: string | null;
  remember: boolean;
};

const initialState: State = {
  username: '',
  password: '',
  confirmationMessage: null,
  remember: true,
};

const formReducer = (
  state: State,
  action: { type: string; field?: string; value?: string | null }
) => {
  if (!action.field) return state;
  switch (action.type) {
    case 'updateFieldValue':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const config: ConfigType = {
  tags: {
    register: {
      render: 'Register',
      attributes: {
        name: {
          type: String,
          required: true,
        },
      },
    },
  },
};

interface RegisterProps {
  name: string;
}

const Register: React.FC<RegisterProps> = ({ name }: RegisterProps) => {
  const auth = useAuth();
  const [registerUser, { error, loading }] = useRegistration();
  const [authenticateUser, { loading: signInLoading, error: signInError }] =
    useAuthenticateUserMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const [state, dispatch] = useReducer<React.Reducer<State, any>>(
    formReducer,
    initialState
  );

  const { setComponentState } = useFormState('register', name);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = () => {
    setComponentState(JSON.stringify(state));
  };

  useEffect(() => {
    handleChange();
  }, [state]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'updateFieldValue',
      field: event.target.name,
      value: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (state.confirmationMessage) {
      dispatch({
        type: 'updateFieldValue',
        field: 'confirmationMessage',
        value: null,
      });
    }

    try {
      if (!showSignIn) {
        const { data } = await registerUser({
          variables: {
            username: state.username,
            password: state.password,
          },
        });
        if (
          data?.registerUser &&
          data?.registerUser?.user?.id &&
          data?.registerUser?.user?.username &&
          data?.registerUser?.jwt
        ) {
          auth.login(data?.registerUser.user, data?.registerUser.jwt);
          router.push('/today');
        }
      } else {
        const { data } = await authenticateUser({
          variables: {
            username: state.username,
            password: state.password,
          },
        });

        if (
          data?.authenticateUser &&
          data?.authenticateUser?.user?.id &&
          data?.authenticateUser?.user?.username &&
          data?.authenticateUser?.jwt
        ) {
          auth.login(data?.authenticateUser.user, data?.authenticateUser.jwt);
          router.push('/today');
        }
      }

      // if (props.onRegister) {
      //   props.onRegister();
      // }
    } catch {
      // handled by error
    }
  };

  return (
    <div className="flex h-screen w-full">
      <div className="my-24 w-full md:my-auto ">
        <form className="mx-16 flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="">
            <div className="text-center text-3xl font-semibold">
              {showSignIn ? 'Sign In' : 'Create your account'}
            </div>
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                style={{ color: 'black' }}
                htmlFor="username"
                value="Your email"
              />
            </div>
            <input
              className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700 "
              id="username"
              type="text"
              name="username"
              value={state.username}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                style={{ color: 'black' }}
                htmlFor="password1"
                value="Password"
              />
            </div>
            <div className="relative">
              <input
                id="password1"
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={state.password}
                onChange={handleInputChange}
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700 "
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 px-4 text-gray-600 hover:text-gray-800 focus:outline-none"
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>

          <div className="flex w-full justify-center">
            <div className="absolute bottom-12 flex flex-col justify-center ">
              <div className="mb-8 flex w-full justify-center">
                <Button
                  style={{
                    width: '170px',
                    borderRadius: '2rem',
                    backgroundColor: 'rgb(30 64 175)',
                  }}
                  className=" bg-blue-800 "
                  type="submit"
                  disabled={loading || signInLoading}
                >
                  {showSignIn ? (
                    <div className="mr-1">Sign In</div>
                  ) : (
                    <div className="mr-1">Register</div>
                  )}

                  <IoArrowForward />
                </Button>
              </div>
              <div className="text-center align-baseline">
                {showSignIn ? (
                  <div className="flex">
                    <div className="mr-1">Need to sign up?</div>
                    <div
                      className="underline"
                      onClick={() => {
                        setShowSignIn(false);
                      }}
                    >
                      Register
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div className="mr-1">Already Registered?</div>
                    <div
                      className="underline"
                      onClick={() => {
                        setShowSignIn(true);
                      }}
                    >
                      Sign In
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {error && (
            <Alert className="ab mt-4" color="warning">
              {error.message}
            </Alert>
          )}
          {signInError && (
            <Alert className="ab mt-4" color="warning">
              {signInError.message}
            </Alert>
          )}
          {state.confirmationMessage && (
            <Alert className="ab mt-4" color="warning">
              {state.confirmationMessage}
            </Alert>
          )}
        </form>
      </div>
    </div>
  );
};

export { Register, config as RegisterConfig };
