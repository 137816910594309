import type { ConfigType } from '@markdoc/markdoc';
import * as React from 'react';

import { extractTextFromNode } from './extractTextFromNode';

const config: ConfigType = {
  tags: {
    select: {
      render: 'RadioSelect',
      children: ['option'],
      attributes: {
        name: {
          type: String,
          required: true,
        },
        other: {
          type: [Boolean, String],
          default: false,
        },
        multiple: {
          type: [Boolean, Number],
          default: false,
        },
      },
    },
  },
};

export interface RadioOption {
  id: string;
  label: string;
}

interface RadioSelectProps {
  name: string; // Name attribute for the radio group
  options: RadioOption[]; // Options for the radio select
  other?: boolean;
}

const RadioSelect: React.FC<RadioSelectProps> = ({ name, options, other }) => {
  const [otherText, setOtherText] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    // If selecting an option other than "Other", clear the "Other" text input
    if (event.target.value !== 'other') {
      setOtherText('');
    }
  };

  const handleOtherInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherText(event.target.value);
    // Automatically select the "Other" radio button when typing in the input
    setSelectedOption('other');
  };

  return (
    <div className="mb-10">
      {options.map((option, index) => (
        <label key={index} className="block">
          <input
            type="radio"
            name={name}
            value={option.id}
            checked={selectedOption === option.id}
            onChange={handleRadioChange}
          />
          {option.label}
        </label>
      ))}
      {other && (
        <>
          <label>
            <input
              type="radio"
              name={name}
              value="other"
              checked={selectedOption === 'other'}
              onChange={handleRadioChange}
            />
            {other === true ? 'Other:' : other}
          </label>
          {selectedOption === 'other' && (
            <input
              type="text"
              value={otherText}
              onChange={handleOtherInputChange}
              className="block bg-inherit"
              name={`${name}-other`}
            />
          )}
        </>
      )}
    </div>
  );
};

// Utility to convert Markdoc AST nodes to RadioOption objects
const convertNodesToOptions = (name: string, nodes: any[]): RadioOption[] =>
  (nodes || []).map((node, index) => ({
    id: `${name}-${index}`,
    label: extractTextFromNode(node.props.children), // Assumes the child is a text node
  }));

export { convertNodesToOptions, RadioSelect, config as radioSelectConfig };
